var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "tableRisk",
        attrs: {
          title: "LBL0001203",
          tableId: "riskRegister01",
          columns: _vm.gridRisk.columns,
          data: _vm.riskReduce.riskbooks,
          merge: _vm.gridRisk.merge,
          gridHeightAuto: "",
          filtering: false,
          columnSetting: false,
          usePaging: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: false,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "ramHazopAssessScenarioId",
                          ibmTaskTypeCd: "ITT0000001",
                          ibmTaskUnderTypeCd: "ITTU000001",
                        },
                        on: { imprChange: _vm.imprChange },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        ref: "tableImpr",
        attrs: {
          title: "LBL0001174",
          tableId: "impr01",
          columns: _vm.gridImpr.columns,
          data: _vm.riskReduce.imprs,
          merge: _vm.gridImpr.merge,
          gridHeightAuto: "",
          filtering: false,
          columnSetting: false,
          usePaging: false,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }